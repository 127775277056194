import { QueryClient } from '@tanstack/react-query';

import { usePwaContentQuery } from '../../utils/data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../../utils/data/graphql/utils/get-content-in-current-language';

export const getPwaContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: usePwaContentQuery.fetcher(),
    queryKey: usePwaContentQuery.getKey(),
  });

  const pwaItemsData = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);
  const cmsContent = { ...pwaItemsData?.[0] };

  return {
    appleCancelLabel: cmsContent.PWAPromptCancelLabel || 'Cancel',
    iosAddHomeInstructionsLabel:
      cmsContent.PWAPromptAddHomeInstructionsLabelApple || "2) Tap 'Add to Home Screen'",
    iosBodyLabel:
      cmsContent.PWAPromptBodyLabelApple ||
      'This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.',
    iosShareInstructionsLabel:
      cmsContent.PWAPromptShareInstructionsLabelApple ||
      "1) Tap the 'Share' button on the menu bar below",
    iosTitleLabel: cmsContent.PWAPromptTitleLabelApple || 'Add to Home Screen',
    safariAddHomeInstructionsLabel:
      cmsContent.PWAPromptAddHomeInstructionsLabelApple || "2) Click 'Add to Dock'",
    safariBodyLabel:
      cmsContent.PWAPromptBodyLabelSafari ||
      'This website has app functionality. Add it to your dock to use it as a standalone app and while offline.',
    safariShareInstructionsLabel:
      cmsContent.PWAPromptShareInstructionsLabelSafari ||
      "1) Click the 'Share' button on the menu bar above",
    safariTitleLabel: cmsContent.PWAPromptTitleLabelSafari || 'Add to Dock',
    webBodyLabel:
      cmsContent.PWAPromptBodyLabelWeb ||
      'This website has app functionality. Install to use it as a standalone app and while offline.',
    webInstallBtnLabel: cmsContent.PWAPromptInstallLabel || 'Install',
  };
};
