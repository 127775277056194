import { QueryClient } from '@tanstack/react-query';

import { SEARCH_FACET_LIMIT } from '../constants/facet-filters';

import {
  type LearnSearchResultsQueryVariables,
  useTopicFacetsQuery,
} from './graphql/_generated/gql-generated';
import { getCommonSearchQueryFilters } from './graphql/utils/get-common-search-query-filters';

export const getTopicFacets = async ({ xForwardedForHeader }: { xForwardedForHeader: string }) => {
  const queryClient = new QueryClient();

  const queryVariables: LearnSearchResultsQueryVariables = {
    limit: SEARCH_FACET_LIMIT,
    where: await getCommonSearchQueryFilters({ xForwardedForHeader }),
  };

  const data = await queryClient.fetchQuery({
    queryFn: useTopicFacetsQuery.fetcher(queryVariables),
    queryKey: useTopicFacetsQuery.getKey(queryVariables),
  });

  return data.LearnDetailPage?.facets;
};

export type TopicFacets = Awaited<Promise<PromiseLike<ReturnType<typeof getTopicFacets>>>>;
