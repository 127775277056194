import { InstallPWAPrompt, MdrtLogo } from '@shared/ui-components';

import { getPwaContent } from '../../page-content-components/pwa-content/get-pwa-content';

import styles from './pwa-prompt.module.scss';

export const PWAPrompt = async () => {
  const pwaCmsContent = await getPwaContent();

  return <InstallPWAPrompt {...pwaCmsContent} webLogo={<MdrtLogo className={styles.logo} />} />;
};
